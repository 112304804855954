export default function CheckIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Icons">
        <rect width="18" height="18" rx="9" fill="white" />
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.3194 7.23811C13.589 6.92361 13.5526 6.45014 13.2381 6.18057C12.9236 5.911 12.4501 5.94743 12.1806 6.26192L8.20764 10.897L6.53033 9.21968C6.23744 8.92679 5.76256 8.92679 5.46967 9.21968C5.17678 9.51258 5.17678 9.98745 5.46967 10.2803L7.14698 11.9577C7.76482 12.5755 8.77788 12.5366 9.34652 11.8732L13.3194 7.23811Z"
          fill="black"
        />
      </g>
    </svg>
  );
}
